import React from 'react'
import { useFetch } from 'usehooks-ts'

import { getDoginalCollectionInfo, getDoginalCollectionsWhitelist } from '@/api'
import { LaunchpadCollectionCardProps } from '@/components/launchpad/LaunchpadCollectionCard'
import MintpadCollectionCard from '@/components/launchpad/MintpadCollectionCard'
import { LaunchpadFetchResult } from '@/types/ILaunchpad'
import { useWalletContext } from '@/WalletContext'

import styles from './LaunchpadLive.module.scss'
import { MintingPageContainer } from './MintingPageContainer'

export function Mintpad() {
  const { address } = useWalletContext()
  const [mintpadCollectionsList, setMintpadCollectionsList] = useState<any>([])
  const [launchpadEarlyAccessWhitelistCollections, setLaunchpadEarlyAccessWhitelistCollections] = useState<string[]>([])

  const baseUrl = import.meta.env.VITE_MARKETPLACE_BACKEND_API || 'https://marketplace-api.dogeord.io/'
  const currentLaunchpadActiveUrl = `${baseUrl}mintpad/list/active`
  let { data, error } = useFetch<LaunchpadFetchResult>(
    `${currentLaunchpadActiveUrl}?limit=100&offset=0&sortParam=launchpadEndDate&sortOrder=asc`
  )

  const fetchCollectionsData = useCallback(async () => {
    let collections: LaunchpadCollectionCardProps[] = []
    if (data) {
      collections = data!.collections.map(
        (collection) =>
          ({
            ...collection,
            imgSrc: collection.imageURI,
            altText: collection.name,
            mintingConfig: {
              maxMintsPerWallet: collection.launchpadMaxMintsPerWallet,
              dogePerMint: collection.launchpadPriceInDoge,
              fixedMinters: 1,
              showStatus: true,
              fundingWalletAddress: collection.launchpadFundingWallet,
              supply: collection.launchpadSupply,
              whitelistedForEarlyAccess: launchpadEarlyAccessWhitelistCollections.includes(collection.symbol),
            },
            steps: [
              {
                label: collection.name,
              },
              {
                label: 'Thank you!',
              },
            ],
            info: collection.description,
            isActive: true,
            launchpadEndTimestamp: new Date(collection.launchpadEndDate),
          } as unknown as LaunchpadCollectionCardProps)
      )
      setMintpadCollectionsList(collections)
    }
  }, [data, launchpadEarlyAccessWhitelistCollections])

  useEffect(() => {
    fetchCollectionsData()
  }, [fetchCollectionsData])

  useEffect(() => {
    const fetchWhitelist = async () => {
      if (address) {
        const whitelistedCollections = await getDoginalCollectionsWhitelist(address)
        setLaunchpadEarlyAccessWhitelistCollections(whitelistedCollections)
      }
    }
    fetchWhitelist()
  }, [address])

  const fetchCollectionData = useCallback(
    async (symbol) => {
      try {
        const collectionResult = await getDoginalCollectionInfo(symbol)
        const collection = collectionResult.collection

        const updatedList = mintpadCollectionsList.map((collectionListItem: any) => {
          if (collectionListItem.symbol === collection.symbol) {
            return {
              ...collectionListItem,
              ...collection,
            }
          }
          return collectionListItem
        })
        setMintpadCollectionsList(updatedList)

        return collection
      } catch (err) {
        console.error(`Failed to fetch info for collection: ${symbol}`, err)
        return null
      }
    },
    [mintpadCollectionsList]
  )

  return (
    <MintingPageContainer>
      {mintpadCollectionsList.length === 0 ? (
        <div className="col-span-full mt-12 mb-18 flex justify-center items-center gap-6">
          <h1 className="text-xl text-left">
            {error
              ? `An unexpected error occured: ${JSON.stringify(error)}`
              : !data
              ? 'Loading'
              : 'There are no active collections at the moment. Please check back later.'}
          </h1>
        </div>
      ) : (
        mintpadCollectionsList
          .filter((collection: any) => !collection.symbol.endsWith('-test'))
          .map((collection: any): any => (
            <div className="self-start" key={`MintpadCollectionCard-${collection.symbol}`}>
              <MintpadCollectionCard
                isActive={collection.isActive}
                name={collection.name}
                symbol={collection.symbol}
                steps={collection.steps}
                altText={collection.altText}
                imgSrc={collection.imgSrc}
                mintingConfig={collection.mintingConfig}
                description={collection.description}
                twitterLink={collection.twitterLink}
                websiteLink={collection.websiteLink}
                launchpadEndTimestamp={collection.launchpadEndTimestamp}
                launchpadEarlyAccessMinutes={collection.launchpadEarlyAccessMinutes}
                launchpadMintedSupply={collection.launchpadMintedSupply}
                fetchCollectionData={fetchCollectionData}
              />
            </div>
          ))
      )}
    </MintingPageContainer>
  )
}
