import { Link, useNavigate } from 'react-router-dom'

import { DisplayType } from '@/types/common'
import { DoginalsCollection } from '@/types/dogeNft'
import { Drc20Data } from '@/types/drc20'

import { LoadingRow } from './LoadingRow'
import { TableRow } from './Row'
import { HeadTitles, TableHead } from './TableHead'

type TableProps = {
  drc20List?: Drc20Data[]
  doginalList?: DoginalsCollection[]
  headTitles: HeadTitles
  dogePriceInUSD: number
  viewAllURL: string
  type: DisplayType
}

export const LandingPageTable = ({
  drc20List,
  doginalList,
  headTitles,
  dogePriceInUSD,
  type,
  viewAllURL,
}: TableProps) => {
  const navigate = useNavigate()

  const handleViewAllButtonClick = () => {
    navigate(viewAllURL)
  }

  const renderLoading = () => {
    return (
      <>
        {Array.from({ length: 5 }, (cb) => cb).map((_, idx) => (
          <LoadingRow key={idx} />
        ))}
      </>
    )
  }

  const renderDrc20Rows = () => {
    return (
      <>
        {drc20List && drc20List.length > 0
          ? drc20List
              .map((drc) => ({
                ...drc,
                imageURI: `https://drc-20-icons.s3.eu-central-1.amazonaws.com/${drc.tick.toLowerCase()}.png`,
              }))
              .map(
                (
                  { tick, floorPrice, imageURI, activityVolume, currentSupply, changePercent, marketplaceVerified },
                  idx
                ) => {
                  return (
                    <TableRow
                      key={idx}
                      idx={idx}
                      dogePriceInUSD={dogePriceInUSD}
                      imageURI={imageURI}
                      name={tick}
                      floorPrice={floorPrice}
                      symbol={tick}
                      type={type}
                      volume={activityVolume}
                      currentSupply={Number(currentSupply)}
                      changePercent={Number(changePercent)}
                      marketplaceVerified={marketplaceVerified || false}
                    />
                  )
                }
              )
          : renderLoading()}
      </>
    )
  }

  const renderDoginalRows = () => {
    return (
      <>
        {doginalList && doginalList.length > 0
          ? doginalList.map(
              ({ name, floorPrice, imageURI, symbol, activityVolume, dogepadCollection, marketplaceVerified }, idx) => {
                return (
                  <TableRow
                    key={idx}
                    idx={idx}
                    imageURI={imageURI}
                    dogePriceInUSD={dogePriceInUSD}
                    name={name}
                    floorPrice={floorPrice}
                    symbol={symbol}
                    type={type}
                    volume={activityVolume}
                    isDogepadCollection={dogepadCollection || false}
                    marketplaceVerified={marketplaceVerified || false}
                  />
                )
              }
            )
          : renderLoading()}
      </>
    )
  }
  return (
    <div className="w-full xl:max-w-[600px] flex flex-col items-center mb-[24px]">
      <table className="table-fixed min-w-full h-fit mb-[48px]">
        <TableHead {...headTitles} />
        <tbody>
          <tr className="h-[12px]" />
          {type === DisplayType.DRC20 ? renderDrc20Rows() : renderDoginalRows()}
        </tbody>
      </table>
      <Link
        to={viewAllURL}
        style={{
          color: '#000',
          width: 'fit-content',
          fontSize: '16px',
          borderRadius: '12px',
          textDecoration: 'underline',
        }}
        onClick={handleViewAllButtonClick}
      >
        View all
      </Link>
    </div>
  )
}
