import {
  CloseOutlined,
  GithubOutlined,
  MediumOutlined,
  MenuOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'
import { Image, Layout, Menu } from 'antd'
import type { Property } from 'csstype'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import { DiscordIcon } from '@/assets/icons/discord'
import Disclaimer, { disclaimerLatestVersionDate } from '@/components/Disclaimer'
import Maintenance from '@/components/Maintenance'
import Stats from '@/components/Stats'
import WalletConnect from '@/components/WalletConnect'

import './index.css'

const { Header, Content, Footer } = Layout

const layoutStyle = {
  backgroundColor: '#ffffff',
  color: '#000000',
}

const wrapperStyle = {
  justifyContent: 'center',
  background: '#ffffff',
  height: '48px',
  paddingRight: '10px',
  position: 'absolute' as Property.Position,
  top: 0,
  zIndex: 1,
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid #eff2f5',
}

const headerStyle = {
  backgroundColor: 'white',
  position: 'relative' as Property.Position,
  top: '51px',
  height: '50px',
  zIndex: 1,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1280px',
  margin: '0 auto',
  marginTop: '5px',
}

const menuStyle = {
  backgroundColor: 'transparent',
  color: '#000000',
  fontSize: '14px',
  fontWeight: 'bold',
  top: 0,
  right: '20px',
  marginLeft: '0px',
  borderBottom: 0,
  justifyContent: 'flex-start',
}

const linkStyle = { color: 'white', lineHeight: '60px', padding: '0 8px' }

const contentStyle = {
  borderTop: '1px solid #eff2f5',
  padding: '0 16px',
  textAlign: 'center' as Property.TextAlign,
  background: 'linear-gradient(rgb(248, 250, 253) 0%, rgba(248, 250, 253, 0) 413px)',
  marginTop: '70px',
}

const contentWrapperStyle = {
  maxWidth: '1280px',
  margin: '0 auto',
  paddingTop: '50px',
  paddingBottom: '150px',
}

const footerStyle = {
  backgroundColor: '#ffffff',
  color: '#64748b',
  fontSize: '16px',
  maxWidth: '1280px',
  margin: '0 auto',
  width: '100%',
}

const linkIconStyle = { lineHeight: '48px', padding: '0 8px' }

type PageBaseProps = {
  children: any
  topBanner?: ReactNode
  bottomBanner?: ReactNode
}

const IS_MAINTENANCE_MODE = false

const PageBase = ({ children, topBanner, bottomBanner }: PageBaseProps) => {
  const navigator = useNavigate()
  const { pathname } = useLocation()
  const [disclaimerAcceptedAt, setDisclaimerAcceptedAt] = useLocalStorage('disclaimerAcceptedAt', 0)
  const [menuState, setMenuState] = useState<string[]>([])
  const navigationItems = [
    { key: 'marketplace', label: 'Marketplace', disabled: false },
    { key: 'launchpad', label: 'Launchpad', disabled: false },
    { key: 'service/inscribe', label: 'Inscribe', disabled: false },
    // { key: 'service/labradoge', label: 'Labradoge', disabled: false },
    { key: 'labradoges', label: 'Labradoges', disabled: false },
    { key: 'explorer/ranking', label: 'Explorer' },
    { key: 'wallet/balances', label: 'Balances' },
    {
      key: 'account',
      label: (
        <div className="lg:hidden block">
          <WalletConnect />
        </div>
      ),
    },
  ]

  const acceptDisclaimer = useCallback(
    (timestamp) => {
      console.log('acceptDisclaimer')
      setDisclaimerAcceptedAt(timestamp)
    },
    [setDisclaimerAcceptedAt]
  )

  const navigateTo = useCallback(
    (e: any) => {
      switch (e.key) {
        case 'home':
          navigator('/')
          break
        case 'explorer/ranking':
          navigator('/explorer/ranking')
          break
        case 'marketplace':
          navigator('/marketplace?type=DOGINALS')
          break
        case 'launchpad':
          navigator('/launchpad')
          break
        case 'service/mint':
          navigator('/service/inscribe')
          break
        case 'service/inscribe':
          navigator('/service/inscribe')
          break
        case 'service/labradoge':
          //Refresh if same page
          pathname === '/service/labradoge' ? navigator(0) : navigator('/service/labradoge')
          break
        case 'service/re-roll':
          //Refresh if same page
          pathname === '/service/re-roll' ? navigator(0) : navigator('/service/re-roll')
          break
        case 'labradoges':
          navigator('/labradoges')
          break
        case 'wallet/balances':
          navigator('/wallet/balances')
          break
        default:
          console.error(e)
      }
    },
    [navigator, pathname]
  )

  let activeTabPathname = pathname
  if (pathname.includes('mintpad')) {
    activeTabPathname = 'launchpad'
  }
  const activeTab = navigationItems.find((item) => activeTabPathname?.includes(item.key))?.key || 'home'

  // Add state to track keys being pressed
  const [keysPressed, setKeysPressed] = useState(new Set())

  // Add hook to handle keydown and keyup events
  useEffect(() => {
    const downHandler = ({ key }: { key: string }) => {
      setKeysPressed((keysPressed) => new Set(keysPressed).add(key))
    }

    const upHandler = ({ key }: { key: string }) => {
      setKeysPressed((keysPressed) => {
        const newKeysPressed = new Set(keysPressed)
        newKeysPressed.delete(key)
        return newKeysPressed
      })
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) // Empty array ensures effect is only run on mount and unmount

  // @ts-ignore
  return (
    <Layout className="layout" style={layoutStyle}>
      <div style={wrapperStyle}>
        <Stats />
      </div>
      <Header style={headerStyle}>
        <title>Doge Labs Market</title>
        <meta name="description" content="Doge Labs Market" />
        <link rel="icon" href="/favicon.ico" />
        <div className="mt-7">
          <a href="/" target="_self" rel="noopener noreferrer">
            <Image src="/logo.svg" alt="Logo" width={56} height={56} preview={false} />
          </a>
        </div>
        {/*<div className="xxs:hidden md:flex mt-1 ml-4">*/}
        {/*  <BaseButton onClick={() => navigateTo({ key: 'unleash-doginals' })}>Mint 'Unleash Doginals'</BaseButton>*/}
        {/*</div>*/}
        {/*<div className="md:hidden mt-1 ml-2">*/}
        {/*  <BaseButton onClick={() => navigateTo({ key: 'unleash-doginals' })}>Mint 'UD'</BaseButton>*/}
        {/*</div>*/}
        <Menu
          className="ant-menu-style"
          style={menuStyle}
          mode="horizontal"
          defaultSelectedKeys={['home']}
          selectedKeys={[activeTab]}
          items={navigationItems}
          onClick={navigateTo}
          onOpenChange={(newState) => setMenuState(newState)}
          overflowedIndicator={menuState.indexOf('rc-menu-more') > -1 ? <CloseOutlined /> : <MenuOutlined />}
        />
        <a
          href="https://twitter.com/verydogelabs"
          target="_blank"
          rel="noreferrer"
          className="hide-mobile"
          style={linkStyle}
        >
          <img alt="Twitter" src="/twitter.svg" />
        </a>
        <a
          href="https://discord.com/invite/fjtwfDFHFr"
          target="_blank"
          rel="noreferrer"
          className="hide-mobile"
          style={linkStyle}
        >
          <img alt="Discord" src="/discord.svg" />
        </a>
        <div className="ml-2 hidden lg:block">
          <WalletConnect />
        </div>
      </Header>
      {topBanner ? topBanner : null}
      <Content
        style={{
          ...contentStyle,
          marginTop: topBanner ? 0 : '70px',
        }}
      >
        {/*100vh - 414px for footer and header*/}
        <div className="App relative p-0 sm:p-4 min-h-[calc(100vh-414px)]" style={contentWrapperStyle}>
          {IS_MAINTENANCE_MODE ? (
            <Maintenance />
          ) : disclaimerAcceptedAt > disclaimerLatestVersionDate ? (
            children
          ) : (
            <Disclaimer acceptDisclaimer={acceptDisclaimer} />
          )}
        </div>
        <img alt="Doge" id="doge" src="/doge.png" />
      </Content>
      {bottomBanner ? bottomBanner : null}
      <Footer style={footerStyle}>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <h6>Creator</h6>
            <a href="https://forms.gle/vo4qHrbWQ4KE3TfEA">List Collection</a>
            <a href="https://forms.gle/V5p5wykJUpnLa6Ty9">Launch Collection</a>
            <a href="https://forms.gle/CcJDdPXT18RqPftc7">Submit Logo</a>
          </div>
          <div className="flex flex-col hide-mobile-flex" style={{ minWidth: '100px' }}>
            <h6>Services</h6>
            <a href="/marketplace">Marketplace</a>
            <a href="/labradoges">Labradoges</a>
            <a href="/service/inscribe">Inscription Service</a>
            <div className="text-black">
              Wallet:{' '}
              <a
                href="https://chrome.google.com/webstore/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp"
                target="_blank"
                rel="noreferrer"
              >
                Chrome
              </a>{' '}
              |{' '}
              <a
                href="https://github.com/verydogelabs/dogelabs-wallet/raw/main/dogelabs-firefox-latest-github.zip"
                target="_blank"
                rel="noreferrer"
              >
                Firefox
              </a>
            </div>
            <a href="https://doge-labs.gitbook.io/doginals/" target="_blank">
              Academy
            </a>
            <a href="/wallet/balances">Balance</a>
            <a href="https://wonky-ord.dogeord.io/" target="_blank">
              Doginal Indexer
            </a>
          </div>
          <div className="flex flex-col">
            <h6>Explorer</h6>
            <a href="/explorer/ranking">Ranking</a>
            <a href="/explorer/trending">Trending</a>
            <a href="/explorer/recently">Recently Added</a>
          </div>
          <div className="flex flex-col hide-mobile-flex">
            <h6>Other</h6>
            <a href="https://docs.drc-20.org/" target="_blank">
              drc-20 standard
            </a>
            <a href="https://github.com/verydogelabs" target="_blank" rel="noreferrer">
              Documentation
            </a>
            <a href="https://terms.drc-20.org" target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            <a href="https://privacy.drc-20.org" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="flex flex-col show-mobile-only-flex">
          <div className="flex flex-col mt-8">
            <h6>Creator</h6>
            <a href="https://forms.gle/vo4qHrbWQ4KE3TfEA">List Collection</a>
            <a href="https://forms.gle/V5p5wykJUpnLa6Ty9">Launch Collection</a>
            <a href="https://forms.gle/CcJDdPXT18RqPftc7">Submit Logo</a>
          </div>
          <div className="flex flex-col mt-8">
            <h6>Services</h6>
            <a href="/marketplace">Marketplace</a>
            <a href="/labradoges">Labradoges</a>
            <a href="/service/inscribe">Inscription Service</a>
            <div className="text-black">
              Wallet:{' '}
              <a
                href="https://chrome.google.com/webstore/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp"
                target="_blank"
                rel="noreferrer"
              >
                Chrome
              </a>{' '}
              |{' '}
              <a
                href="https://github.com/verydogelabs/dogelabs-wallet/raw/main/dogelabs-firefox-latest-github.zip"
                target="_blank"
                rel="noreferrer"
              >
                Firefox
              </a>
            </div>
            <a href="https://doge-labs.gitbook.io/doginals/" target="_blank">
              Academy
            </a>
            <a href="/wallet/balances">Balance</a>
            <a href="https://wonky-ord.dogeord.io/" target="_blank">
              Doginal Indexer
            </a>
          </div>
          <div className="flex flex-col mt-8">
            <h6>Explorer</h6>
            <a href="/explorer/ranking">Ranking</a>
            <a href="/explorer/trending">Trending</a>
            <a href="/explorer/recently">Recently Added</a>
          </div>
          <div className="flex flex-col mt-8">
            <h6>Other</h6>
            <a href="https://docs.drc-20.org/" target="_blank">
              drc-20 standard
            </a>
            <a href="https://github.com/verydogelabs" target="_blank" rel="noreferrer">
              Documentation
            </a>
            <a href="https://terms.drc-20.org" target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            <a href="https://privacy.drc-20.org" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="flex flex-col flex-start mt-8">
          <div className="flex flex-row flex-start text-xl">
            <a href="https://twitter.com/verydogelabs" target="_blank" rel="noreferrer" style={linkIconStyle}>
              <TwitterOutlined />
            </a>
            <a href="https://discord.com/invite/fjtwfDFHFr" target="_blank" rel="noreferrer" style={linkIconStyle}>
              <DiscordIcon />
            </a>
            <a href="https://github.com/verydogelabs" target="_blank" rel="noreferrer" style={linkIconStyle}>
              <GithubOutlined />
            </a>
            <a
              href="https://medium.com/doge-labs-drc-20-doginals"
              target="_blank "
              rel="noreferrer"
              style={linkIconStyle}
            >
              <MediumOutlined />
            </a>
            <a href="https://www.youtube.com/@VeryDogeLabs" target="_blank " rel="noreferrer" style={linkIconStyle}>
              <YoutubeOutlined />
            </a>
          </div>
        </div>
      </Footer>
    </Layout>
  )
}

export default PageBase
