import { Progress } from 'antd'
import { useNavigate } from 'react-router'

import { useTimeRemaining } from '@/hooks/useTimeRemaining'
import { numberToLocaleString } from '@/utils'

import { cardHoverAnimation } from '../../LandingPage'
import { LandingPagePrimaryButton } from '../button/LandingPagePrimaryButton'

enum MintType {
  launchpad = 'launchpad',
  mintpad = 'mintpad',
}

type LandingPageLiveMintCardProps = {
  currentSupply: number
  dogePriceInUsd: number
  endBlock: number
  endingTime: number
  imageURL: string
  name: string
  price: number
  startBlock: number
  symbol: string
  totalSupply: number
  type: MintType
}

const LandingPageLiveMintCard = ({
  currentSupply,
  dogePriceInUsd,
  endBlock,
  endingTime,
  imageURL,
  name,
  price,
  startBlock,
  symbol,
  totalSupply,
  type,
}: LandingPageLiveMintCardProps) => {
  const navigate = useNavigate()
  const timeRemaining = useTimeRemaining(endingTime)

  const handleClickMint = (type: MintType) => {
    type === MintType.launchpad ? navigate('/launchpad') : navigate('/mintpad')
  }

  const signalColor = type === MintType.launchpad ? '#E951D0' : '#F9B628'
  const hasMintStarted = timeRemaining.hours <= 48
  const isComingSoon = timeRemaining.hours > 90 * 24

  return (
    <div className="w-[252px] md:w-[260px] flex-col ml-[12px] md:ml-[0] mb-[16px]">
      <span className="block text-start ml-[16px]">{hasMintStarted ? 'Mint ends in' : 'Mint starts in'}</span>
      <div className="h-[32px] leading-[32px] w-[260px] my-8px px-[16px] flex justify-between font-bold mb-[16px]">
        {isComingSoon ? (
          <div style={{ color: signalColor }}>COMING SOON</div>
        ) : (
          <>
            <div className="w-[60px] bg-[#F6F6F6] rounded-[6px]" style={{ color: signalColor }}>
              {`${hasMintStarted ? timeRemaining.days : timeRemaining.days - 48}d`}
            </div>
            :
            <div className="w-[60px] bg-[#F6F6F6] rounded-[6px]" style={{ color: signalColor }}>
              {`${timeRemaining.hours}h`}
            </div>
            :
            <div className="w-[60px] bg-[#F6F6F6] rounded-[6px]" style={{ color: signalColor }}>
              {`${timeRemaining.minutes}m`}
            </div>
          </>
        )}
      </div>
      <div
        className={`relative w-full h-[450px] border-[2.5px] border-solid rounded-[12px] flex-col p-[12px] shadow-[0px_6px_16px_0px_#0000001A] ${cardHoverAnimation}`}
        style={{ borderColor: type === MintType.launchpad ? signalColor : 'none' }}
      >
        {startBlock && endBlock ? (
          <div className="absolute top-[24px] right-[24px] bg-[#00000026] backdrop-blur-[10px] rounded-[15px] text-[white] px-[12px] py-[2px]">
            {`#${startBlock.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })} - #${endBlock.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}`}
          </div>
        ) : (
          ''
        )}
        <img
          className="h-2.4/5 w-full block object-cover rounded-[15px] mb-[12px]"
          src={imageURL}
          alt="Drc20"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => (e.currentTarget.src = '/ticks/noicon.png')}
        />
        <div className="h-2.6/5 flex flex-col justify-between pb-[24px] mx-[12px]">
          <span className="block font-bold text-[18px] text-start mb-[12px]">{name}</span>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="inline-block font-bold text-start text-[18px] text-start">
                {price.toLocaleString('en-US', { maximumFractionDigits: 2 })} Ɖ
              </span>
              <span className="inline-block text-start text-[12px] text-[#12121280] text-start">
                ${(price * dogePriceInUsd).toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </span>
            </div>
          </div>
          <div className=" w-full mx-auto my-[16px]">
            <div className="flex justify-between items-center">
              <span className="inline-block">Total</span>
              <span>
                <span className={'font-bold'} style={{ color: signalColor }}>
                  {numberToLocaleString((currentSupply / totalSupply) * 100)} % &nbsp;
                </span>
                <span>{`(${currentSupply.toLocaleString()}/${totalSupply.toLocaleString()})`}</span>
              </span>
            </div>
            <Progress
              percent={Math.min(100, (currentSupply / totalSupply) * 100)}
              strokeColor={signalColor}
              showInfo={false}
            />
          </div>
          <LandingPagePrimaryButton
            styles={{
              height: '46px',
              fontSize: '14px',
              background:
                signalColor === '#E951D0'
                  ? 'linear-gradient(89.93deg, #F501FA 2.76%, #FC4EE0 55.4%, #ED01B9 94.76%)'
                  : 'linear-gradient(89.93deg, #FA6A01 2.76%, #F38401 55.4%, #ED6401 94.76%)',
              opacity: hasMintStarted ? 1 : 0.5,
            }}
            onClick={() => handleClickMint(type)}
          >
            {hasMintStarted ? 'Mint' : 'Starting soon'}
          </LandingPagePrimaryButton>
        </div>
      </div>
      <span className="block text-end font-bold uppercase mt-[8px] mr-[16px]" style={{ color: signalColor }}>
        {type === MintType.launchpad ? 'Launchpad' : 'Mintpad'}
      </span>
    </div>
  )
}

export { LandingPageLiveMintCard, MintType }
