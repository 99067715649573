import { calculateTimeRemaining } from '@/utils'

const useTimeRemaining = (endTimestamp: number) => {
  const startTime = Date.now()
  const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining(startTime, endTimestamp))

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateTimeRemaining(Date.now(), endTimestamp))
    }, 10000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return remainingTime
}

export { useTimeRemaining }
