import { Button, Progress } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useFetch, useInterval, useLocalStorage } from 'usehooks-ts'

import AmountInput from '@/components/AmountInput'
import WalletConnect from '@/components/WalletConnect'
import useToast from '@/hooks/useToast'
import Sentry from '@/main'
import { useWalletContext } from '@/WalletContext'

import Countdown from '../Countdown'
import ServiceContentContainer from '../ServiceContentContainer'
import { LaunchpadCollectionCardProps } from './LaunchpadCollectionCard'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../pages/index.css'

const SATOSHIS_PER_DOGECOIN: number = 100000000
const twoDays = 2 * 24 * 60 * 60 * 1000
const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: 1,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
}

interface Step {
  label: string
  description?: string
}

const MintpadCollectionCard: React.FC<LaunchpadCollectionCardProps> = ({
  name,
  symbol,
  imgSrc,
  altText,
  mintingConfig,
  steps,
  isActive,
  description,
  twitterLink,
  websiteLink,
  launchpadEndTimestamp = 0,
  launchpadEarlyAccessMinutes = 0,
  launchpadMintedSupply = 0,
  highlightColor = '#FFAE42',
  fetchCollectionData,
}) => {
  const baseUrl = import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api-rc.dogeord.io'
  const currentDogePriceUrl = `${baseUrl}/ticks/currentDogePrice`
  const { data: currentDogePrice } = useFetch<number>(currentDogePriceUrl)

  const slider = useRef<Slider>(null)
  const doginalName = name

  const { sendDoge, address, connected, balance } = useWalletContext()
  const toast = useToast()

  const isFreeMint = mintingConfig.dogePerMint === 0

  const [activeStep, setActiveStep] = useState(0)
  const [serviceWalletAddress, setServiceWalletAddress] = useState(mintingConfig.fundingWalletAddress)
  const [enoughFunds, setEnoughFunds] = useState(false)
  const [amount, setAmount] = useState(isFreeMint ? mintingConfig.maxMintsPerWallet || 1 : 1)
  const [dogeAmount, setDogeAmount] = useState(0)

  const earlyAccessTimespan = launchpadEarlyAccessMinutes * 60 * 1000

  const earlyAccessStartTimestamp = launchpadEndTimestamp - twoDays - earlyAccessTimespan
  const publicMintingStartTimestamp = launchpadEndTimestamp - twoDays
  const isEarlyAccessMintingPhase = earlyAccessStartTimestamp < Date.now() && Date.now() < publicMintingStartTimestamp
  const isFarAway = Date.now() < publicMintingStartTimestamp - twoDays * 45

  const launchpadStartTimestamp = mintingConfig.whitelistedForEarlyAccess
    ? earlyAccessStartTimestamp
    : publicMintingStartTimestamp
  const [showCountdown, setShowCountdown] = useState(launchpadStartTimestamp && launchpadStartTimestamp > Date.now())
  const soldOut = launchpadMintedSupply >= mintingConfig.supply

  useInterval(
    () => {
      if (fetchCollectionData) {
        fetchCollectionData(symbol)
      }
    },
    // Delay in milliseconds or null to stop it
    !showCountdown && !soldOut ? 2500 : null
  )

  useEffect(() => {
    const handleReset = async () => {
      setServiceWalletAddress(mintingConfig.fundingWalletAddress)
      setActiveStep(0)
      // @ts-ignore
      slider?.current?.slickGoTo(0)
      setAmount(1)
      setDogeAmount(0)
    }

    if (amount > mintingConfig.maxMintsPerWallet) {
      setAmount(mintingConfig.maxMintsPerWallet)
    }

    if (amount && amount > 0) {
      const calcDogeAmount = amount * mintingConfig.dogePerMint
      setDogeAmount(calcDogeAmount)
    }

    const enoughFundsToBuy = balance.total / SATOSHIS_PER_DOGECOIN > dogeAmount
    const hasEnoughFunds = isFreeMint || enoughFundsToBuy
    setEnoughFunds(hasEnoughFunds)

    if (activeStep === 1) {
      setTimeout(handleReset, 5000)
    }
  }, [
    setDogeAmount,
    amount,
    activeStep,
    setEnoughFunds,
    dogeAmount,
    balance.total,
    mintingConfig.maxMintsPerWallet,
    mintingConfig.dogePerMint,
    mintingConfig.fundingWalletAddress,
    isFreeMint,
  ])

  const handleInscriptionRequest = async () => {
    let fundingResult = 'none'
    if (!isFreeMint) {
      // send doge to service wallet
      fundingResult = await sendDoge(serviceWalletAddress, Math.round(dogeAmount * SATOSHIS_PER_DOGECOIN))
    }

    // increment the doginal counter
    if (doginalName && symbol && activeStep === 0) {
      const url = `${
        import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api-rc.dogeord.io'
      }/launchpad/inscribe?name=${symbol}&amount=${amount}&dogeAmount=${dogeAmount}&fundingAddress=${
        mintingConfig.fundingWalletAddress
      }&receiverAddress=${address}&txid=${fundingResult}`

      try {
        const response = await fetch(url)
        const data = await response.json()

        if (data.error || data.status >= 400) {
          console.error('Failed to mint', data)

          if (data.error === 'You already minted a free doginal for this collection') {
            toast.showErrorToast('Unable to mint. You already minted a free doginal for this collection')
            // setMintpadUserMintedCollection(true)
          } else {
            toast.showErrorToast('Unable to mint. Please try again later')
          }

          return false
        } else {
          // setMintpadUserMintedCollection(true)
          return true
        }
      } catch (error) {
        console.error(error)
        toast.showErrorToast('Unable to mint. Please try again later')
        Sentry.captureMessage('Unable to mint. Please try again later', {
          extra: {
            address,
            balance,
            doginalName,
            symbol,
            isFreeMint,
          },
        })
        return false
      }
    }
  }

  const mintDisabled = !connected || !address || !amount || !enoughFunds || !isActive || !!showCountdown

  return (
    <ServiceContentContainer
      title={steps[activeStep]?.label || ''}
      description={steps[activeStep]?.description || ''}
      maxWidth={'400px'}
      minHeight={'300px'}
      showTitleBorder={activeStep !== 0}
      twitterLink={twitterLink}
      websiteLink={websiteLink}
      info={description}
      highlightColor={highlightColor}
    >
      <Slider ref={slider} {...sliderSettings}>
        <div>
          <div className="flex flex-col sm:flex-row justify-between mb-4">
            <img
              className="w-100% sm:w-[160px] mb-4 sm:mb-0"
              id={doginalName}
              src={imgSrc}
              alt={altText}
              style={{ borderRadius: '10px' }}
            />
            <div className="flex-col flex items-start">
              <div className="flex flex-row justify-between items-center">
                <div className="flex-col flex items-start">
                  <div className="text-xs text-gray-500">Total</div>
                  <div className="text-md font-bold w-24 text-left">
                    {isFarAway ? 'TBA' : `${parseFloat((mintingConfig.dogePerMint * amount).toFixed(1))} Doge`}
                  </div>
                  <div className="text-xs text-gray-500">
                    {isFarAway ? '' : `~${(mintingConfig.dogePerMint * amount * (currentDogePrice || 0)).toFixed(2)}`}
                  </div>
                </div>
                <div className="flex-1"></div>
                <AmountInput
                  className={!isActive ? 'opacity-30' : ''}
                  value={!isActive ? 1 : amount}
                  onChange={setAmount}
                  max={mintingConfig.maxMintsPerWallet}
                />
              </div>
              {mintingConfig.showStatus && (
                <div className="mt-4" style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>Minted</span>
                    <span>
                      <span style={{ color: highlightColor, fontWeight: 'bold' }}>
                        {Math.min(100, (launchpadMintedSupply / mintingConfig.supply) * 100).toFixed(0)}%
                      </span>{' '}
                      ({Math.min(launchpadMintedSupply, mintingConfig.supply)}/{mintingConfig.supply})
                    </span>
                  </div>
                  <Progress
                    percent={Math.min(100, (launchpadMintedSupply / mintingConfig.supply) * 100)}
                    strokeColor={highlightColor}
                    showInfo={false}
                  />
                </div>
              )}
              {soldOut && (
                <>
                  <Button
                    disabled={true}
                    style={{
                      background: '#ccc',
                      border: 'none',
                      borderRadius: '16px',
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      height: '46px',
                      marginTop: '16px',
                      marginBottom: '8px',
                      width: '100%',
                    }}
                  >
                    SOLD OUT
                  </Button>
                </>
              )}
              {connected && enoughFunds && !soldOut && !isFarAway && (
                <>
                  <Button
                    disabled={mintDisabled}
                    style={{
                      background: mintDisabled ? '#ccc' : highlightColor,
                      border: 'none',
                      borderRadius: '16px',
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      height: '46px',
                      marginTop: '16px',
                      marginBottom: '8px',
                      width: '100%',
                    }}
                    onClick={async () => {
                      await handleInscriptionRequest()
                      slider && slider.current && slider.current.slickNext()
                      setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    }}
                  >
                    Mint
                  </Button>
                </>
              )}
              {isFarAway && (
                <div className="flex flex-row mb-4 justify-start text-left">
                  <Button
                    disabled
                    style={{
                      background: '#ccc',
                      border: 'none',
                      borderRadius: '16px',
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      height: '46px',
                      marginTop: '16px',
                      marginBottom: '8px',
                      width: '100%',
                    }}
                  >
                    Coming Soon
                  </Button>
                </div>
              )}
            </div>
          </div>
          {isEarlyAccessMintingPhase && launchpadEarlyAccessMinutes > 0 && <div>Early access minting started</div>}
          {!mintingConfig.showStatus && <div className={connected ? 'min-h-0' : 'min-h-3.5'} />}
          {!mintingConfig.showStatus && <div className="mt-10" style={{ width: '100%' }} />}
          {!connected && !showCountdown && (
            <div style={{ marginTop: '16px', marginBottom: '8px', textAlign: 'center' }}>
              <WalletConnect disabled={!isActive} />
            </div>
          )}
          {connected && !enoughFunds && isActive && (
            <div style={{ marginTop: '16px', marginBottom: '8px', color: 'red', textAlign: 'left' }}>
              <strong>
                Insufficient funds, please get some DOGE in order to mint {amount} {altText} here.
              </strong>
            </div>
          )}
          {connected && !enoughFunds && !isActive && <div className="min-h-10" />}
          {mintingConfig.whitelistedForEarlyAccess && (
            <div
              className="flex w-48 rounded-md p-2 text-xs text-center justify-center m-auto mt-4"
              style={{ backgroundColor: 'RGBA(35, 213, 129, 0.2)', color: '#23D581' }}
            >
              <strong>Whitelist Access Confirmed</strong>
            </div>
          )}
          {!soldOut && !isFarAway && (
            <div className="flex flex-row mb-4 justify-center text-left">
              {showCountdown ? (
                <div className="flex flex-row justify-center items-center text-center w-100%">
                  <span style={{ color: '#232530', fontSize: '18px', fontWeight: 'bold', marginRight: '8px' }}>
                    Starts in
                  </span>
                  <Countdown
                    value={launchpadStartTimestamp!}
                    onFinish={() => setShowCountdown(false)}
                    itemStyle={{ backgroundColor: highlightColor }}
                  />
                </div>
              ) : (
                <span className="text-xxs">
                  By clicking "Mint", you agree to the{' '}
                  <a
                    href="https://terms.drc-20.org"
                    target="_blank"
                    style={{ color: '#000', textDecoration: 'underline' }}
                  >
                    Terms of use
                  </a>{' '}
                  and acknowledge that you are choosing to mint the NFT with the understanding that it may be worth less
                  than the mint price, and may end up being worth nothing at all.
                </span>
              )}
            </div>
          )}
        </div>
        <div>
          <div className="flex flex-col justify-center items-center">
            <img id={`${doginalName}-thanks`} src="/images/doginals/thanks.png" alt="thanks" height="300" width="300" />
            <div className="mt-8 text-xl">
              You will receive your {name} NFTs in your wallet within 48 hours of the mint ending.
            </div>
          </div>
        </div>
      </Slider>
    </ServiceContentContainer>
  )
}

export default MintpadCollectionCard
