import { useNavigate } from 'react-router'
import { useMediaQuery } from 'usehooks-ts'

import { getDoginalOfferActivity, getDrc20Activity } from '@/api'
import { DisplayType } from '@/types/common'
import { SortingTimeFrame } from '@/types/sorting'
import { breakDownPriceInDoge, calculateTimeRemaining } from '@/utils'

import { LandingPageDropdown } from '../components/dropdown/LandingPageDropdown'
import { LandingPageSortingPill } from '../components/sorting/LandingPageSortingPill'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

enum SalesType {
  All = 'All',
  NFT = 'NFT',
  drc20 = 'drc20',
}

enum SortBy {
  Recent = 'Recent',
  Top = 'Top',
}

type Sale = {
  amount?: number
  inscriptionId: string
  inscriptionNumber: number
  name: string // doginalName - tick
  price: number
  totalPrice?: number
  symbol?: string
  time: string
  type: DisplayType
}

type LandingPageRecentSalesProps = {
  dogePriceInUSD: number
}

const LandingPageRecentSales = ({ dogePriceInUSD }: LandingPageRecentSalesProps) => {
  const isMobile = useMediaQuery('(min-width: 320px)')
  const isTablet = useMediaQuery('(min-width: 768px)')
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const navigate = useNavigate()
  const [recentDoginalSales, setRecentDoginalSales] = useState<Sale[]>([])
  const [recentDRCSales, setRecenDRCSales] = useState<Sale[]>([])
  const [recentSales, setRecentSales] = useState<Sale[]>([])

  const [selectedType, setSelectedType] = useState<SalesType>(SalesType.All)
  const [sortingTimeFrame, setSortingTimeFrame] = useState<SortingTimeFrame>(SortingTimeFrame.TwentyFourHours)
  const [sortingType, setSortingType] = useState<SortBy>(SortBy.Recent)

  const now = Date.now()

  const sales = useMemo(() => {
    if (selectedType === SalesType.All) {
      return recentSales
    } else if (selectedType === SalesType.NFT) {
      return recentDoginalSales
    } else {
      return recentDRCSales
    }
  }, [recentDRCSales, recentDoginalSales, recentSales, selectedType])

  const getGridArea = (idx: number) => {
    switch (idx) {
      case 1:
        return '1 / 1 / 2 / 2'
      case 2:
        return '2 / 1 / 3 / 2'
      case 3:
        return '3 / 1 / 4 / 2'
      case 4:
        return '4 / 1 / 5 / 2'
      case 5:
        return '1 / 2 / 2 / 3'
      case 6:
        return '2 / 2 / 3 / 3'
      case 7:
        return '3 / 2 / 4 / 3'
      case 8:
        return '4 / 2 / 5 / 3'
      case 9:
        return '1 / 3 / 2 / 4'
      case 10:
        return '2 / 3 / 3 / 4'
      case 11:
        return '3 / 3 / 4 / 4'
      case 12:
        return '4 / 3 / 5 / 4'
    }
  }

  const getTimePassed = (hours: number) => {
    if (hours < 1) {
      return '< 1 hour ago'
    } else if (hours === 1) {
      return '1 hour ago'
    } else {
      return `${hours} hours ago`
    }
  }

  const timeframeForRequest = useMemo(() => {
    switch (sortingTimeFrame) {
      case SortingTimeFrame.OneHour:
        return '1h'
      case SortingTimeFrame.SixHours:
        return '6h'
      case SortingTimeFrame.TwentyFourHours:
        return '24h'
      case SortingTimeFrame.SevenDays:
        return '7d'
    }
  }, [sortingTimeFrame])

  const fetchRecentSales = useCallback(async () => {
    const newestFirst = (a: Sale, b: Sale) => Number(new Date(b.time)) - Number(new Date(a.time))
    const expensiveFirst = (a: Sale, b: Sale) => b.price - a.price

    const { activityList: fetchedDoginalSales } = await getDoginalOfferActivity({
      collectionSymbol: '',
      action: 'sale',
      offset: 0,
      limit: 20,
      history: sortingType === SortBy.Top ? timeframeForRequest : undefined,
      sortParam: sortingType === SortBy.Top ? 'top' : undefined,
    })
    const fetchedDrc20Sales = await getDrc20Activity({
      tick: '',
      action: 'sale',
      offset: 0,
      limit: 20,
      history: sortingType === SortBy.Top ? timeframeForRequest : undefined,
      sortParam: sortingType === SortBy.Top ? 'top' : undefined,
    })

    const doginalSales = fetchedDoginalSales?.map((x) => ({
      inscriptionId: x.inscriptionId,
      inscriptionNumber: x.inscriptionNumber,
      name: x.doginalName,
      symbol: x.collectionSymbol,
      price: x.price,
      time: x.createdAt,
      type: DisplayType.DOGINALS,
    }))
    const drc20Sales = fetchedDrc20Sales.activities?.map((x) => ({
      amount: x.amount,
      inscriptionId: x.inscriptionId,
      inscriptionNumber: x.inscriptionNumber,
      name: x.tick,
      price: x.price,
      totalPrice: x.totalPrice,
      time: x.createdAt,
      type: DisplayType.DRC20,
    }))

    setRecentDoginalSales(doginalSales.slice(0, 12) as Sale[])
    setRecenDRCSales(
      drc20Sales.sort(sortingType === SortBy.Recent ? newestFirst : expensiveFirst).slice(0, 12) as Sale[]
    )
    setRecentSales(
      [...doginalSales, ...drc20Sales].sort(sortingType === SortBy.Recent ? newestFirst : expensiveFirst).slice(0, 12)
    )
  }, [timeframeForRequest, sortingType])

  const handleClick = (type: DisplayType, name: string, inscriptionId?: string) => {
    if (type === DisplayType.DOGINALS) {
      navigate(`/marketplace/doginals/${name}/${inscriptionId}`)
    } else {
      window.open(`https://wonky-ord.dogeord.io/shibescription/${inscriptionId}`, '_blank')
    }
  }

  useEffect(() => {
    fetchRecentSales()
  }, [fetchRecentSales])

  const TimeFrameFilter = () => {
    if (sortingType === SortBy.Recent) return null

    if (isDesktop) {
      return (
        <LandingPageSortingPill
          options={Object.values(SortingTimeFrame)}
          defaultValue={sortingTimeFrame}
          onChange={(x) => {
            setSortingTimeFrame(x as SortingTimeFrame)
          }}
        />
      )
    } else {
      return (
        <LandingPageDropdown
          items={Object.values(SortingTimeFrame)}
          onClick={(item) => setSortingTimeFrame(item as SortingTimeFrame)}
        />
      )
    }
  }

  return (
    <div className="mb-[48px]">
      <LandingPageTitle blackText="Recent" orangeText="Sales" style={{ marginBottom: '8px' }} />

      <div className="w-full flex flex-wrap gap-[8px] justify-between items-center mb-[48px] mt-[24px]">
        <LandingPageSortingPill
          options={Object.values(SalesType)}
          defaultValue={SalesType.All}
          onChange={(x) => {
            setSelectedType(x as SalesType)
          }}
        />
        <div className="flex flex-wrap gap-[12px] md:gap-[32px] items-center">
          <TimeFrameFilter />
          <LandingPageDropdown
            items={Object.values(SortBy).reverse()}
            onClick={(item) => setSortingType(item as SortBy)}
            style={{ width: '120px' }}
          />
        </div>
      </div>
      <div className="w-full grid grid-rows-[repeat(4,1fr)] gap-x-8 gap-y-4 xs:grid-cols-[repeat(1,1fr)] md:grid-cols-[repeat(2,1fr)] xl:grid-cols-[repeat(3,1fr)]">
        {sales.map((x, idx) => (
          <div
            key={idx}
            className="w-full min-w-[320px] flex justify-between items-center cursor-pointer p-2 rounded-[8px] hover:bg-gray-100 cursor-pointer"
            style={{
              gridArea: getGridArea(idx + 1),
              display: isDesktop ? 'flex' : isTablet && idx < 8 ? 'flex' : isMobile && idx < 4 ? 'flex' : 'none',
            }}
          >
            <div className="flex justify-start items-center">
              <div className="mr-[12px] w-[32px] text-left mb-[18px]">{idx + 1}</div>

              <div
                className="flex justify-center items-center"
                onClick={() => handleClick(x.type, x.symbol ?? x.name, x.inscriptionId)}
              >
                <div className="flex flex-col items-start">
                  <img
                    src={
                      x.type === DisplayType.DOGINALS
                        ? `https://wonky-ord.dogeord.io/content/${x.inscriptionId}`
                        : `https://drc-20-icons.s3.eu-central-1.amazonaws.com/${x.name.toLowerCase()}.png`
                    }
                    alt={`doginal ${x.name}`}
                    className="w-[64px] h-[64px] object-cover rounded-[8px]"
                    onError={(e) => (e.currentTarget.src = '/ticks/noIcon.svg')}
                  />

                  <span className="inline-block text-[12px] text-left font-medium">
                    {getTimePassed(calculateTimeRemaining(new Date(x.time).getTime(), now).hours)}
                  </span>
                </div>
                <div className="flex flex-col items-start ml-[12px]">
                  <span className="inline-block font-bold text-[14px] md:text-[16px] text-left w-[120px] overflow-hidden whitespace-nowrap text-ellipsis">
                    {x.name}
                  </span>
                  <span className="inline-block text-[12px]">
                    {x.type === DisplayType.DOGINALS && '#'}
                    {x.type === DisplayType.DOGINALS
                      ? x.inscriptionNumber.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })
                      : x.amount?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-end">
              <span className="inline-block font-bold text-[14px] md:text-[16px] w-max">
                {breakDownPriceInDoge(x.totalPrice ?? x.price, { notation: 'compact', compactDisplay: 'short' })} Ɖ
              </span>
              <span className="inline-block text-[12px] opacity-60 w-max">
                $
                {breakDownPriceInDoge((x.totalPrice ?? x.price) * dogePriceInUSD, {
                  notation: 'compact',
                  compactDisplay: 'short',
                })}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export { LandingPageRecentSales }
