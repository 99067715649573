import { Popover } from 'antd'
import { useNavigate } from 'react-router'

import { DisplayType } from '@/types/common'
import { ONE_DOGE_IN_SHIBES, TrustLevel } from '@/utils/constants'
import { numberToLocaleString } from '@/utils/localize'

type TableRowProps = {
  idx: number
  name: string
  imageURI: string
  floorPrice: number
  dogePriceInUSD: number
  symbol: string
  type: DisplayType
  volume: number
  currentSupply?: number
  changePercent?: number
  isDogepadCollection?: boolean
  marketplaceVerified?: boolean
}

export const TableRow = ({
  name,
  floorPrice,
  dogePriceInUSD,
  imageURI,
  volume,
  idx,
  symbol,
  type,
  currentSupply,
  changePercent = 0,
  isDogepadCollection = false,
  marketplaceVerified = false,
}: TableRowProps) => {
  const navigator = useNavigate()

  const handleClick = useCallback(() => {
    navigator(type === DisplayType.DRC20 ? `/marketplace/drc20/${symbol}` : `/marketplace/doginals/${symbol}`)
  }, [symbol, navigator, type])

  const handleTextColor = (percentPriceChange: number) => {
    return percentPriceChange < 0 ? 'text-[#E82C44]' : 'text-[#23D581]'
  }

  return (
    <tr className="h-[90px] hover:bg-gray-100 cursor-pointer" onClick={handleClick}>
      <td className="hidden sm:table-cell whitespace-nowrap py-4 pl-4 text-xs text-start rounded-tl-[8px] rounded-bl-[8px]">
        <span className="text-[16px] ml-[8px]">{idx + 1}</span>
      </td>
      <td className="whitespace-nowrap py-4 text-xs text-start">
        <span className="flex items-center">
          <div className="flex items-center gap-5">
            <img
              className="w-16 h-16 object-contain rounded-[8px]"
              src={imageURI}
              alt="Drc20"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                (e.currentTarget.src = '/ticks/noicon.png')
              }
            />
            <div className="flex">
              <span className="text-[16px] mr-2 font-bold text-[16px] w-100% h-[17px] overflow-hidden whitespace-nowrap text-ellipsis">
                {name}
                {isDogepadCollection && (
                  <Popover content={<div>Dogepad Collection</div>} trigger="hover" placement="right">
                    <img
                      className="inline-block"
                      src="/images/dogepad/dogepad.png"
                      alt="Dogepad Collection"
                      style={{ marginLeft: '5px', height: '14px', width: '14px' }}
                    />
                  </Popover>
                )}
                {marketplaceVerified && (
                  <Popover content={<div>Launchpad Collection</div>} trigger="hover" placement="right">
                    <img
                      className="inline-block"
                      src="/logo.svg"
                      alt="Launchpad Collection"
                      style={{ marginLeft: '5px', height: '18px', width: '18px' }}
                    />
                  </Popover>
                )}
              </span>
            </div>
          </div>
        </span>
      </td>
      <td className="rounded-tr-8px] rounded-br-[8px] whitespace-nowrap py-4 text-xs sm:rounded-tr-[0px] sm:rounded-br-[0px]">
        <div className="flex flex-col items-end justify-end h-full pb-1 pr-4 md:pr-0">
          <span className="font-bold text-[16px] mb-1">{numberToLocaleString(floorPrice / ONE_DOGE_IN_SHIBES)} Ɖ</span>
          <span className="text-[12px]">
            ${numberToLocaleString((dogePriceInUSD * floorPrice) / ONE_DOGE_IN_SHIBES)}
          </span>
        </div>
      </td>
      <td className="hidden sm:table-cell whitespace-nowrap py-4 pr-1 sm:pr-4 text-xs h-full rounded-tr-[8px] rounded-br-[8px]">
        <div className="flex flex-col items-end justify-end h-full pb-1">
          <span className="font-bold text-[16px] mb-1">{numberToLocaleString(volume / ONE_DOGE_IN_SHIBES)} Ɖ</span>
          <span className="text-[12px]">${numberToLocaleString(dogePriceInUSD * (volume / ONE_DOGE_IN_SHIBES))}</span>
        </div>
      </td>
      {currentSupply && (
        <td className="whitespace-nowrap py-4 pr-1 sm:pr-4 text-xs h-full rounded-tr-[8px] rounded-br-[8px]">
          <div className="flex flex-col items-end justify-end h-full pb-1">
            <span className="font-bold text-[16px] mb-1">
              ${numberToLocaleString(dogePriceInUSD * (floorPrice / ONE_DOGE_IN_SHIBES) * currentSupply)}
            </span>
            <span className={`${handleTextColor(changePercent)} text-[12px]`}>
              {changePercent > 0 ? '+' : ''}
              {numberToLocaleString(changePercent)}%
            </span>
          </div>
        </td>
      )}
    </tr>
  )
}
