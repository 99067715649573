import { Skeleton } from 'antd'
import { SwiperSlide } from 'swiper/react'
import { useElementSize } from 'usehooks-ts'

import { Feature, getFeatures } from '@/api'

import { LandingPagePrimaryButton } from '../components/button/LandingPagePrimaryButton'
import { SwiperSlider } from '../components/slider/SwiperSlider'

const LandingPageBanner = () => {
  const [containerRef, { width }] = useElementSize()
  const [bannerItems, setBannerItems] = useState<Feature[]>([])
  const fetchBannerItems = async () => {
    let features = await getFeatures(undefined, 'landingpagebanner')
    features && setBannerItems(features)
  }

  // calculate how many items to show (esp. on bigger screens)
  const slidesToShow = useCallback(
    (defaultAmount) =>
      width
        ? Math.floor(width / 300) > bannerItems.length
          ? bannerItems.length
          : Math.floor(width / 300)
        : defaultAmount,
    [width, bannerItems]
  )

  const slidesPerView = useMemo(() => (width <= 768 ? 'auto' : slidesToShow(4)), [width, slidesToShow])
  const spaceBetweenSlides = useMemo(() => (width <= 768 ? 12 : 24), [width])
  const shouldShowRightButton = useMemo(() => width > 768, [width])

  useEffect(() => {
    fetchBannerItems()
  }, [])

  const Card = ({
    title,
    category = 'FEATURED',
    imageLink,
    CTALink,
    CTAText,
    description,
  }: {
    title: string
    category: string
    imageLink: string
    CTALink: string
    CTAText: string
    description: string
  }) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
      <div
        className="h-[320px] w-[300px] sm:h-full sm:w-full relative cursor-pointer overflow-hidden"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <div className="h-full min-w-[190px] overflow-hidden rounded-[15px] bg-white">
          <img
            src={imageLink}
            alt={`carouselimg-${title}`}
            className={`w-full h-full object-cover aspect-[1/1] rounded-[15px] hover:scale-125 transition-all duration-[0.3s] ease-[ease-in-out] delay-[0s] ${
              isHovered ? 'scale-125' : ''
            }`}
          />
        </div>

        <div
          className={`absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent z-1 transition-all duration-300 rounded-[15px] ${
            isHovered ? 'bg-black/40' : ''
          }`}
        ></div>

        <div className="absolute flex flex-col z-10 h-full justify-end p-4 text-white-1 w-full top-0 text-white">
          <div
            className="absolute bottom-4 transition-transform duration-300"
            style={{ transform: `translateY(${isHovered ? '-136px' : '0px'})` }}
          >
            <div className="text-xl font-bold truncate">{title}</div>
            <div className="flex gap-x-2 items-center">
              <div className="text-sm text-secondary font-semibold truncate uppercase">{category}</div>
            </div>
          </div>
          <div
            className={`transform translate-y-${isHovered ? '0' : 'full'} opacity-${
              isHovered ? '100' : '0'
            } transition-all duration-300`}
          >
            <div className="text-sm text-white-2 mb-4 text-ellipsis overflow-hidden h-[64px]">{description}</div>
            <div className="my-2 flex w-full gap-4">
              <a
                className="text-white-1 w-full"
                onClick={() => {
                  const url = CTALink.split('drc-20.org')[1]
                  window.open(url, '_self', 'rel=noopener noreferrer')
                }}
              >
                <LandingPagePrimaryButton text={CTAText} styles={{ height: '40px', width: '100%' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={containerRef}
      className="h-fit mt-[70px] p-[16px] pt-[32px]"
      style={{ background: 'linear-gradient(147.75deg, #f2f2f2 0%, #fbfbfb 100%)' }}
    >
      {bannerItems.length > 0 ? (
        <SwiperSlider
          spaceBetween={spaceBetweenSlides}
          slidesPerView={slidesPerView}
          shouldShowRightButton={shouldShowRightButton}
          shouldShowNavigationDots={true}
        >
          {bannerItems.map((d, index) => (
            <SwiperSlide key={index}>
              <Card
                title={d.title as string}
                category={d.category as string}
                imageLink={d.imageLink}
                CTALink={d.CTALink}
                CTAText={d.CTAText}
                description={d.description[0]}
                key={index}
              />
            </SwiperSlide>
          ))}
        </SwiperSlider>
      ) : (
        <div className="flex justify-between">
          <Skeleton.Image active style={{ width: '280px', height: '280px', borderRadius: '15px', margin: '24px' }} />
          <Skeleton.Image active style={{ width: '280px', height: '280px', borderRadius: '15px', margin: '24px' }} />
          <Skeleton.Image active style={{ width: '280px', height: '280px', borderRadius: '15px', margin: '24px' }} />
          <Skeleton.Image active style={{ width: '280px', height: '280px', borderRadius: '15px', margin: '24px' }} />
          <Skeleton.Image active style={{ width: '280px', height: '280px', borderRadius: '15px', margin: '24px' }} />
          <Skeleton.Image active style={{ width: '280px', height: '280px', borderRadius: '15px', margin: '24px' }} />
        </div>
      )}
    </div>
  )
}

export { LandingPageBanner }
