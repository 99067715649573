import { Skeleton } from 'antd'

const LoadingMintCard = () => {
  return (
    <div className="h-675 w-[260px]">
      <div className="relative max-w-[360px] h-[570px] border-[2.5px] border-[#e0dede] border-solid rounded-[12px] flex-col p-[12px] shadow-[0px_6px_16px_0px_#0000001A]">
        <Skeleton.Input
          active
          block
          size="small"
          style={{ width: '100%', height: '260px', borderRadius: '6px', marginBottom: '32px' }}
        />
        <div className="h-260px flex flex-col items-start justify-between">
          <Skeleton.Input active block size="small" style={{ display: 'flex', width: '120px', borderRadius: '6px' }} />
          <Skeleton.Input active block size="small" style={{ display: 'flex', width: '40px', borderRadius: '6px' }} />
          <Skeleton.Input
            active
            block
            size="small"
            style={{ display: 'flex', width: '100%', height: '16px', borderRadius: '6px', marginTop: '32px' }}
          />
          <Skeleton.Button
            active
            block
            size="small"
            style={{ display: 'flex', width: '100%', height: '60px', borderRadius: '6px', marginBottom: '16px' }}
          />
        </div>
      </div>
    </div>
  )
}

export { LoadingMintCard }
