import { SwiperSlide } from 'swiper/react'

import { getLaunchpadMints, getMintpadMints, PadMint } from '@/api'

import { LandingPageLiveMintCard, MintType } from '../components/cards/LandingPageLiveMintCard'
import { LoadingMintCard } from '../components/cards/LoadingMintCard'
import { SwiperSlider } from '../components/slider/SwiperSlider'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

type LandingPageLiveMintsProps = {
  dogePriceInUSD: number
}

type MintCardProps = {
  currentSupply: number
  endBlock: number
  endingTime: number
  imageURL: string
  price: number
  startBlock: number
  name: string
  symbol: string
  totalSupply: number
  type: MintType.launchpad
}

const LandingPageLiveMints = ({ dogePriceInUSD }: LandingPageLiveMintsProps) => {
  const [liveMints, setLiveMints] = useState<MintCardProps[]>([])
  const [liveMintsLoaded, setLiveMintsLoaded] = useState<boolean>(false)

  const fetchLiveMints = useCallback(async () => {
    const { data: launchpadData } = await getLaunchpadMints({
      limit: 10,
      offset: 0,
      sort: 'launchpadEndTimestamp',
      order: 'asc',
    })
    const { data: mintpadData } = await getMintpadMints({
      limit: 10,
      offset: 0,
      sort: 'mintpadEndTimestamp',
      order: 'asc',
    })

    const launchpadMints: MintCardProps[] = launchpadData.collections?.map((x: PadMint) => ({
      currentSupply: x.launchpadMintedSupply,
      endBlock: Number(x.minInscriptionNumber),
      endingTime: new Date(x.launchpadEndDate).getTime(),
      imageURL: x.imageURI,
      price: x.launchpadPriceInDoge,
      startBlock: Number(x.maxInscriptionNumber),
      name: x.name,
      symbol: x.symbol,
      totalSupply: x.supply,
      type: MintType.launchpad,
    }))

    const mintpadMints: MintCardProps[] = mintpadData.collections?.map((x: PadMint) => ({
      currentSupply: x.launchpadMintedSupply,
      endBlock: Number(x.minInscriptionNumber),
      endingTime: new Date(x.launchpadEndDate).getTime(),
      imageURL: x.imageURI,
      price: x.launchpadPriceInDoge,
      startBlock: Number(x.maxInscriptionNumber),
      name: x.name,
      symbol: x.symbol,
      totalSupply: x.launchpadSupply,
      type: MintType.mintpad,
    }))
    const soonerDeadlinesFirst = (a: MintCardProps, b: MintCardProps) => {
      let comparison = 0
      if (a.endingTime > b.endingTime) {
        comparison = 1
      } else if (a.endingTime < b.endingTime) {
        comparison = -1
      }
      return comparison
    }

    setLiveMints(
      [...launchpadMints.sort(soonerDeadlinesFirst), ...mintpadMints.sort(soonerDeadlinesFirst)].filter(
        (x) => x.totalSupply > x.currentSupply
      )
    )

    setLiveMintsLoaded(true)
  }, [])

  useEffect(() => {
    fetchLiveMints()
  }, [fetchLiveMints])

  return (
    <>
      {liveMintsLoaded && liveMints?.length && liveMints.length > 0 && (
        <div className="mb-[48px]">
          <LandingPageTitle blackText="Live" orangeText="Mints" style={{ marginBottom: '24px' }} />
          <div className="h-fit max-h-[600px]">
            <SwiperSlider spaceBetween={32} isLoop={false}>
              {liveMints.map(
                (
                  { currentSupply, endBlock, endingTime, imageURL, name, price, startBlock, symbol, totalSupply, type },
                  idx
                ) => (
                  <SwiperSlide key={idx}>
                    <LandingPageLiveMintCard
                      currentSupply={currentSupply}
                      dogePriceInUsd={dogePriceInUSD}
                      endBlock={endBlock}
                      endingTime={endingTime}
                      imageURL={imageURL}
                      price={price}
                      startBlock={startBlock}
                      name={name}
                      symbol={symbol}
                      totalSupply={totalSupply}
                      type={type}
                    />
                  </SwiperSlide>
                )
              )}
            </SwiperSlider>
          </div>
        </div>
      )}
      {!liveMintsLoaded && (
        <div className="h-fit max-h-[600px]">
          <SwiperSlider spaceBetween={32} isLoop={false}>
            {Array(4)
              .fill({})
              .map((_, idx) => (
                <SwiperSlide key={idx}>
                  <LoadingMintCard />
                </SwiperSlide>
              ))}
          </SwiperSlider>
        </div>
      )}
    </>
  )
}

export { LandingPageLiveMints }
