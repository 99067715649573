import { Popover } from 'antd'
import { useNavigate } from 'react-router'

import { ONE_DOGE_IN_SHIBES } from '@/utils/constants'

const DoginalTrendingTableRow = ({
  index,
  imageURI,
  name,
  symbol,
  floorPrice,
  volume,
  sales,
  holders,
  supply,
  dogePrice,
  totalListed,
  visible,
  isDogepadCollection,
  marketplaceVerified,
}: {
  index: any
  imageURI: string
  name: string
  symbol: string
  floorPrice: number
  volume: number
  sales: number
  holders: number
  supply: number
  dogePrice: number
  totalListed: number
  visible?: boolean
  isDogepadCollection?: boolean
  marketplaceVerified?: boolean
}) => {
  const navigator = useNavigate()

  const handleClick = (symbol: string) => {
    navigator(`/marketplace/doginals/${symbol}`)
  }
  return (
    <>
      {visible && (
        <tr className="text-xs w-full">
          <td
            onClick={() => handleClick(symbol)}
            className="py-5 text-left sticky left-0 marketplace-table-cell-shadow-right xxs:bg-similar-to-bg-color tableScrollBreakpoint:bg-inherit cursor-pointer"
          >
            <span className="flex items-center">
              <span className="pr-4 w-8 text-center">{index}</span>
              <img src={imageURI} alt="logo" className="w-7 object-cover rounded-3xl" />
              {name && <span className="ml-1 overflow-x-hidden text-ellipsis">{name}</span>}
              {isDogepadCollection && (
                <Popover content={<div>Dogepad Collection</div>} trigger="hover" placement="right">
                  <img
                    src="/images/dogepad/dogepad.png"
                    alt="Dogepad Collection"
                    style={{ marginLeft: '5px', height: '14px', width: '14px' }}
                  />
                </Popover>
              )}
              {marketplaceVerified && (
                <Popover content={<div>Launchpad Collection</div>} trigger="hover" placement="right">
                  <img
                    src="/logo.svg"
                    alt="Launchpad Collection"
                    style={{ marginLeft: '5px', height: '18px', width: '18px' }}
                  />
                </Popover>
              )}
            </span>{' '}
          </td>
          <td className="py-5 text-left pl-5">
            <div className="flex flex-col whitespace-nowrap">
              <span>
                {totalListed > 0
                  ? (floorPrice / ONE_DOGE_IN_SHIBES).toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  : 0}{' '}
                DOGE
              </span>
              <span className="text-slate-500 text-xxxs">
                $
                {totalListed > 0
                  ? ((dogePrice * floorPrice) / ONE_DOGE_IN_SHIBES).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </div>
          </td>
          <td className="py-5 text-left">
            <div className="flex flex-col whitespace-nowrap">
              <span>
                {Number(volume / ONE_DOGE_IN_SHIBES).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                DOGE
              </span>
              <span className="text-slate-500 text-xxxs">
                $
                {(dogePrice * Number(volume / ONE_DOGE_IN_SHIBES)).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </td>
          <td className="py-5 text-left">{totalListed}</td>
          <td className="py-5 text-left">{sales}</td>
          <td className="py-5 text-left">{holders}</td>
          <td className="py-5 text-left">{supply}</td>
        </tr>
      )}
    </>
  )
}

export default DoginalTrendingTableRow
