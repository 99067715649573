import { GlobalOutlined, TwitterOutlined } from '@ant-design/icons'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { DiscordIcon } from '@/assets/icons/discord'
import { AssetTransferTab } from '@/pages/AccountPage'
import { DoginalsCollection } from '@/types/dogeNft'

import DoginalsCollectionInfo from './DoginalsInfo'

type DoginalsCollectionHeaderProps = {
  doginalsCollectionInfo: DoginalsCollection
  supply: number
}

const SocialLink = ({ href, children }: { href: string; children: ReactNode }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" style={{ lineHeight: '48px', padding: '0 8px' }}>
      {children}
    </a>
  )
}

const DoginalsCollectionHeader = ({ doginalsCollectionInfo, supply }: DoginalsCollectionHeaderProps) => {
  return (
    <div className="w-full flex flex-col gap-8 border-2 border-account-page-background rounded-lg bg-account-info-background px-3 py-8 md:px-6 lg:px-10 lg:py-14">
      <div className="w-full flex xxs:justify-between lg:justify-start lg:gap-8">
        <img alt="Collection Image" className="w-28 h-28 rounded-full" src={doginalsCollectionInfo.imageURI} />
        <DoginalsCollectionInfo
          className="xxs:hidden lg:flex lg:min-w-lg"
          doginalsCollectionInfo={doginalsCollectionInfo}
          supply={supply}
        />
        <div className="flex flex-col flex-grow">
          <div className="flex flex-row flex-start justify-end text-xl">
            {doginalsCollectionInfo.websiteLink && (
              <SocialLink href={doginalsCollectionInfo.websiteLink}>
                <GlobalOutlined />
              </SocialLink>
            )}
            {doginalsCollectionInfo.twitterLink && (
              <SocialLink href={doginalsCollectionInfo.twitterLink}>
                <TwitterOutlined />
              </SocialLink>
            )}
            {doginalsCollectionInfo.discordLink && (
              <SocialLink href={doginalsCollectionInfo.discordLink}>
                <DiscordIcon />
              </SocialLink>
            )}
          </div>
          <div className="w-full flex justify-end items-center lg:pt-4">
            <Link to={`/account?activeTab=${AssetTransferTab.Doginals}`}>
              <button className="bg-selected-color xxs:px-2 xs:px-6 py-2 rounded-lg text-white font-semibold xs:whitespace-nowrap">
                LIST FOR SALE
              </button>
            </Link>
          </div>
        </div>
      </div>
      <DoginalsCollectionInfo
        className="lg:hidden w-full"
        doginalsCollectionInfo={doginalsCollectionInfo}
        supply={supply}
      />
    </div>
  )
}

export default DoginalsCollectionHeader
